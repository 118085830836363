import Layout from "./Layout";
import OverviewComp from "../Components/OverviewComp";

const Overview = () => {
  return (
    <Layout>
      <section className="mb-[286px]">
        <OverviewComp />
      </section>
    </Layout>
  );
};

export default Overview;
