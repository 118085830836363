const BASE_FILE_PATH = "/3d-model/exterior";

export const ROTATE_DIFF = 190;
export const DEFAULT_ROTATE = -142;

export const numericSort = (a, b) => {
  const aNum = parseInt(a.match(/\d+/)[0], 10);
  const bNum = parseInt(b.match(/\d+/)[0], 10);
  return aNum - bNum;
};

export const getImageList = (imageContext, sortByName = true) => {
  let images = [];

  const importAll = (context) => context.keys().map(context);
  images = importAll(imageContext);

  if (sortByName) images.sort(numericSort);

  return images;
};

export const EXTERIOR_MENU_LIST = [
  { path: `${BASE_FILE_PATH}/layout`, text: "Layout" },
  { path: `${BASE_FILE_PATH}/block-a`, text: "Block A" },
  { path: `${BASE_FILE_PATH}/block-b`, text: "Block B" },
  { path: `${BASE_FILE_PATH}/block-c`, text: "Block C" },
  { path: `${BASE_FILE_PATH}/block-d`, text: "Block D" },
  { path: `${BASE_FILE_PATH}/block-e`, text: "Block E" },
  { path: `${BASE_FILE_PATH}/block-f`, text: "Block F" },
  { path: `${BASE_FILE_PATH}/block-g`, text: "Block G" },
  { path: `${BASE_FILE_PATH}/block-h`, text: "Block H" },
  { path: `${BASE_FILE_PATH}/block-i`, text: "Block I" },
  { path: `${BASE_FILE_PATH}/block-j`, text: "Block J" },
];

export const TAG_LIST = [
  {
    mb: "mb-3",
    labelColor: "",
    disabled: false,
    isChecked: false,
    stateName: "isLabelShow",
    label: "Show all / Hide all",
  },
  {
    mb: "mb-3",
    disabled: false,
    isChecked: false,
    labelColor: "#F8B0B0",
    stateName: "shivAangan",
    label: "Shiv Angan - 3BHK",
  },
  {
    mb: "mb-3",
    disabled: false,
    isChecked: false,
    labelColor: "#F8B0B0",
    stateName: "tulsiAangan",
    label: "Tulsi Angan - 3BHK",
  },
  {
    mb: "mb-3",
    disabled: false,
    isChecked: false,
    labelColor: "#FFF494",
    stateName: "suryaAangan",
    label: "Surya Angan - 4BHK",
  },
  {
    mb: "mb-3",
    disabled: false,
    isChecked: false,
    labelColor: "#FFF494",
    stateName: "prakrutiAangan",
    label: "Prakruti Angan - 4BHK",
  },
  {
    mb: "mb-3",
    disabled: false,
    isChecked: false,
    labelColor: "#FFF494",
    stateName: "premAangan",
    label: "Prem Angan - 4BHK",
  },
  {
    mb: "",
    disabled: false,
    isChecked: false,
    labelColor: "#FFF494",
    stateName: "swapnaAangan",
    label: "Swapna Angan - 4BHK",
  },
];

export const USER_ID = "userId";
export const SESSION_ID = "sessionId";
export const GEO_LOCATION = "geoLocation";

export const TAG_STATE = {
  shivAangan: false,
  premAangan: false,
  suryaAangan: true,
  tulsiAangan: false,
  swapnaAangan: false,
  prakrutiAangan: false,
};

export const TAG_SHOW = {
  shivAangan: true,
  premAangan: true,
  tulsiAangan: true,
  suryaAangan: true,
  swapnaAangan: true,
  prakrutiAangan: true,
};

export const BLOCK_AANGAN_2D_PLANS = {
  A1: "Plan_SwapnaAangan_3", // no image
  A2: "Plan_TulsiAngan",
  A3: "Plan_TulsiAngan",
  A4: "Plan_TulsiAngan",
  A5: "Plan_TulsiAngan",
  A6: "Plan_TulsiAngan",
  A7: "Plan_SwapnaAangan_5", // no image
  A8: "Plan_SwapnaAangan_6", // no image
  A9: "Plan_TulsiAngan", // tulsi and jblock
  A10: "Plan_TulsiAngan",
  A11: "Plan_TulsiAngan",
  A12: "Plan_TulsiAngan",
  A12a: "Plan_TulsiAngan",
  A14: "Plan_SwapnaAangan", // no image

  B1: "Plan_PrakrutiAangan_3", // no image
  B2: "Plan_ShivAngan_2",
  B3: "Plan_ShivAngan_2",
  B4: "Plan_ShivAngan_2",
  B5: "Plan_PremAngan_2",
  B6: "Plan_PremAngan",
  B7: "Plan_ShivAngan",
  B8: "Plan_ShivAngan",
  B9: "Plan_ShivAngan",
  B10: "Plan_PrakrutiAangan", // no image

  C1: "Plan_PrakrutiAangan", // no image
  C2: "Plan_ShivAngan",
  C3: "Plan_ShivAngan",
  C4: "Plan_ShivAngan",
  C5: "Plan_PremAngan",
  C6: "Plan_PremAngan",
  C7: "Plan_ShivAngan",
  C8: "Plan_ShivAngan",
  C9: "Plan_ShivAngan",
  C10: "Plan_PrakrutiAangan", // no image

  D1: "Plan_PrakrutiAangan_5", // no image
  D2: "Plan_ShivAngan",
  D3: "Plan_ShivAngan",
  D4: "Plan_ShivAngan",
  D5: "Plan_PremAngan", // H_block
  D6: "Plan_PremAngan", // H_block
  D7: "Plan_ShivAngan",
  D8: "Plan_ShivAngan",
  D9: "Plan_ShivAngan",
  D10: "Plan_PrakrutiAangan_5", // no image

  E1: "Plan_SwapnaAangan_4", // no image
  E2: "Plan_SuryaAangan_2", // E_block
  E3: "Plan_SuryaAngan", // E_block
  E4: "Plan_SuryaAngan", // E_block
  E5: "Plan_SwapnaAangan_7", // no image

  F1: "Plan_SwapnaAangan_2", // no image
  F2: "Plan_SuryaAngan",
  F3: "Plan_SuryaAngan",
  F4: "Plan_SuryaAngan",
  F5: "Plan_SuryaAangan_3",

  G1: "Plan_PrakrutiAangan_2", // no image
  G2: "Plan_ShivAngan",
  G3: "Plan_ShivAngan",
  G4: "Plan_ShivAngan",
  G5: "Plan_ShivAngan",
  G6: "Plan_ShivAngan_2",
  G7: "Plan_ShivAngan_2",
  G8: "Plan_ShivAngan_2",
  G9: "Plan_ShivAngan_2",
  G10: "Plan_PrakrutiAangan_4", // no image

  H1: "Plan_PrakrutiAangan_6", // no image
  H2: "Plan_ShivAngan",
  H3: "Plan_ShivAngan",
  H4: "Plan_ShivAngan",
  H5: "Plan_ShivAngan",
  H6: "Plan_ShivAngan",
  H7: "Plan_ShivAngan",
  H8: "Plan_ShivAngan",
  H9: "Plan_ShivAngan",
  H10: "Plan_PrakrutiAangan_6", // no image

  I1: "Plan_PrakrutiAangan", // no image
  I2: "Plan_ShivAngan",
  I3: "Plan_ShivAngan",
  I4: "Plan_ShivAngan",
  I5: "Plan_ShivAngan",
  I6: "Plan_ShivAngan",
  I7: "Plan_ShivAngan",
  I8: "Plan_ShivAngan",
  I9: "Plan_ShivAngan",
  I10: "Plan_PrakrutiAangan",  // no image

  J1: "Plan_SwapnaAangan",  // no image
  J2: "Plan_TulsiAngan",
  J3: "Plan_TulsiAngan",
  J4: "Plan_TulsiAngan",
  J5: "Plan_TulsiAngan",
  J6: "Plan_SwapnaAangan_8", // no image
  J7: "Plan_SwapnaAangan_9",  // no image
  J8: "Plan_TulsiAngan",
  J9: "Plan_TulsiAngan",
  J10: "Plan_TulsiAngan",
  J11: "Plan_TulsiAngan",
  J12: "Plan_SwapnaAangan",  // no image
};

export const Tulsi_Angan = [
  { name: "A2", position: [74, 9, 1] },
  { name: "A3", position: [72, 9, 5] },
  { name: "A4", position: [70, 9, 9] },
  { name: "A5", position: [68, 9, 13] },
  { name: "A6", position: [66, 9, 17] },
  { name: "A9", position: [48, 9, 8] },
  { name: "A10", position: [50, 9, 5] },
  { name: "A11", position: [51, 9, 1] },
  { name: "A12", position: [53, 9, -3] },
  { name: "A12a", position: [55, 9, -7] },
  { name: "J2", position: [48, 9, 54] },
  { name: "J3", position: [50, 9, 50] },
  { name: "J4", position: [52, 9, 46] },
  { name: "J5", position: [53.5, 9, 43] },
  { name: "J8", position: [36, 9, 35.5] },
  { name: "J9", position: [34.5, 9, 38.5] },
  { name: "J10", position: [33, 9, 42] },
  { name: "J11", position: [31, 9, 46] },
];

export const Shiv_Angan = [
  { name: "B2", position: [44, 7, -12] },
  { name: "B3", position: [40.5, 7, -6] },
  { name: "B4", position: [38, 7, -1] },
  { name: "B7", position: [22, 7, -9] },
  { name: "B8", position: [25, 7, -14] },
  { name: "B9", position: [28, 7, -20] },
  { name: "C2", position: [17, 7, -26] },
  { name: "C3", position: [14, 7, -20] },
  { name: "C4", position: [11, 7, -14] },
  { name: "C7", position: [-5, 7, -22] },
  { name: "C8", position: [-2, 7, -28] },
  { name: "C9", position: [1, 7, -34] },
  { name: "D2", position: [-9, 7, -39] },
  { name: "D3", position: [-12, 7, -33] },
  { name: "D4", position: [-15, 7, -27] },
  { name: "D7", position: [-25, 7, -47] },
  { name: "D8", position: [-28, 7, -41] },
  { name: "D9", position: [-31, 7, -35] },
  { name: "G5", position: [-26, 7, -6] },
  { name: "G4", position: [-29, 7, 0] },
  { name: "G3", position: [-32, 7, 6] },
  { name: "G2", position: [-35, 7, 12] },
  { name: "G6", position: [-41, 7, -14] },
  { name: "G7", position: [-44, 7, -8] },
  { name: "G8", position: [-47, 7, -2] },
  { name: "G9", position: [-50, 7, 4] },
  { name: "H2", position: [-9, 7, 24] },
  { name: "H3", position: [-6, 7, 18] },
  { name: "H4", position: [-3, 7, 12] },
  { name: "H5", position: [0, 7, 6] },
  { name: "H9", position: [-24, 7, 17] },
  { name: "H8", position: [-21, 7, 11] },
  { name: "H7", position: [-18, 7, 5] },
  { name: "H6", position: [-15, 7, -1] },
  { name: "I2", position: [17, 7, 38] },
  { name: "I3", position: [20, 7, 32] },
  { name: "I4", position: [23, 7, 26] },
  { name: "I5", position: [26, 7, 20] },
  { name: "I6", position: [12, 7, 13] },
  { name: "I7", position: [9, 7, 19] },
  { name: "I8", position: [6, 7, 25] },
  { name: "I9", position: [3, 7, 31] },
];

export const Surya_Angan = [
  { name: "E2", position: [-36, 7, -52] },
  { name: "E3", position: [-39, 7, -46] },
  { name: "E4", position: [-42, 7, -40] },
  { name: "F2", position: [-62, 7, -3] },
  { name: "F3", position: [-59.5, 7, -8] },
  { name: "F4", position: [-56, 7, -14] },
  { name: "F5", position: [-53, 7, -20] },
];

export const Prakruti_Angan = [
  { name: "B1", position: [47, 7, -19] },
  { name: "B10", position: [32, 7, -27] },
  { name: "C1", position: [20, 7, -33] },
  { name: "C10", position: [5, 7, -41] },
  { name: "D1", position: [-5, 7, -47] },
  { name: "D10", position: [-21, 7, -55] },
  { name: "G1", position: [-38, 7, 18] },
  { name: "G10", position: [-53, 7, 10] },
  { name: "H1", position: [-13, 7, 31] },
  { name: "H10", position: [-27, 7, 24] },
  { name: "I1", position: [14, 7, 44] },
  { name: "I10", position: [0, 7, 37] },
];

export const Prem_Angan = [
  { name: "B5", position: [34, 7, 6] },
  { name: "B6", position: [19, 7, -2] },
  { name: "C5", position: [8, 7, -8] },
  { name: "C6", position: [-8, 7, -16] },
  { name: "D5", position: [-19, 7, -21] },
  { name: "D6", position: [-34, 7, -28] },
];

export const Swapna_Angan = [
  { name: "A1", position: [77, 9, -6] },
  { name: "A7", position: [64, 9, 21] },
  { name: "A8", position: [47, 9, 13] },
  { name: "A14", position: [60, 9, -13] },
  { name: "J1", position: [46, 7, 59] },
  { name: "J6", position: [55, 7, 37] },
  { name: "J7", position: [40, 7, 29] },
  { name: "J12", position: [29, 7, 50] },
  { name: "E1", position: [-33, 7, -58] },
  { name: "E5", position: [-45.5, 7, -33] },
  { name: "F1", position: [-63, 7, 4] },
];

export const mobileDevicePattern =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

export const PAGES = { "": "Home", contact: "CONTACT US" };
