import styled from "styled-components";
import { Box, Dialog } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import TagIcon from "Assets/Images/tag-icon.svg";
import TwoDIcon from "Assets/Images/2d-icon.svg";
import compass from "Assets/Images/compass-icon-new.svg";
import CompassIcon from "Assets/Images/compass-icon.svg";
import FullScreenIcon from "Assets/Images/full-screen-icon.svg";
import NormalScreenIcon from "Assets/Images/normal-screen-icon.svg";
import FullScreenWhiteIcon from "Assets/Images/full-screen-white-icon.svg";
import NormalScreenWhiteIcon from "Assets/Images/normal-screen-white-icon.svg";
import Header from "../../Components/3DModels/Header";
import Share from "Components/LayoutComponents/Share";
import {TAG_LIST, TAG_SHOW, TAG_STATE, USER_ID} from "Helpers/Common";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import {
  showHideTag,
  showHideCompass,
  showHideBlockModel,
} from "Redux/General/ManageTag";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import ContactVerification from "Components/ContactVerification/ContactVerification";

const View2dPlanModal = styled(Dialog)`
  .root {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    margin: 0 !important;
    border-radius: 0;
    background: rgba(0, 0, 0, 0.8); /* Black transparent background */

    @media (max-width: 960px) {
      .modal-width {
        //transform: rotate(90deg);
        width: 100%;
        height: 100%; /* Ensure full height on smaller screens */
        display: flex;
        align-items: center;
      }

      .modal-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
      }
    }
  }

  .autocomplete {
    min-width: 30%;
  }
  .number {
    width: 100%;
  }
  .spacing {
    margin-right: 30px;
  }
  .title {
    font-size: 20px;
    font-weight: bold;
    margin-left: 10px;
  }
  .cancelImg {
    height: 20px;
    width: 20px;
    margin: auto;
    top: 7px;
    position: relative;
  }
  .cancelBtn {
      min-width: 34px;
      height: 34px;
      border-radius: 50%;
      font-size: 16px;
      font-weight: bold;
      border: 0;
      float: right;
      position: fixed;
      top: 9px;
      cursor: pointer;
      z-index: 12;
      color: white;
      right: 12px;
  }
    .cancelBtn1 {
        position: fixed;
        top: 0; /* Aligns the top of the container */
        right: 35px; /* Aligns the right side of the container */
        display: flex;
        gap: 10px;
        border-radius: 5px;
        padding: 10px;
        z-index: 1000;  /* Ensures buttons appear on top of the image */
    }
    .cancelBtn button {
        background: transparent;
        border: none;
        color: white;
        font-size: 18px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
    }

    .cancelBtn1 button {
        background: transparent;
        border: none;
        color: white;
        font-size: 18px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
    }

    .cancelBtn1 button:hover {
        color: #e9a1a1; /* Hover color */
    }

    .cancelBtn:hover {
        color: #e9a1a1; /* Hover color */
  }
  .positionRelative {
    position: relative;
    margin: 1.25rem auto 0;
  }
    
    //.yarl__button yarl__navigation_next{
    //    display: none;
    //}
    //.yarl__navigation_nextv{
    //    display: none;
    //}

  .sendOtpBtn {
    background: linear-gradient(263.61deg, #fc0de1 -69.43%, #ffab2b 104.92%);
    color: #fff;
    margin: 0 0 0 10px;

    &.disabled {
      background: lightgray;
    }
  }
`;

const Layout = ({ children, rotateDegree }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.Loader);
  const manageTag = useSelector((state) => state.ManageTag);
  const { floor2DPlan, showCompass } = manageTag;
  const zoomRef = React.useRef(null);
  const [tagShow, setTagShow] = useState(false);
  const [tagList, setTagList] = useState(TAG_LIST);
  const [openModal, setModalState] = useState(false);
  const [isFullScreen, setFullScreenEnabled] = useState(false);
  const [getContact, setContact] = useState(false);

  const [viewName, setViewName] = useState(
    window.location.pathname.split("/").pop()
  );

  const openContactInfo = useCallback(() => {
    setTimeout(() => {
      let isDataStored = localStorage.getItem(USER_ID) ? true : false;
      setContact(!isDataStored);
    }, 2000);
  }, []);

  useEffect(() => {
    openContactInfo();
  }, [openContactInfo]);



  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setFullScreenEnabled(true);
    } else {
      document.exitFullscreen();
      setFullScreenEnabled(false);
    }
  };

  let location = useLocation();
  const removeIcon = ["shiv-agan", "prem-agan", "amenities"];

  useEffect(() => {
    const pageName = location.pathname.split("/").pop();
    setViewName(() => pageName);

    setTagList((prev) => {
      return prev.map((item) => {
        if (pageName !== "layout" && item.label !== TAG_LIST[0].label)
          item.disabled = true;
        else item.disabled = false;
        return item;
      });
    });
  }, [location]);

  useEffect(() => {
    setTagList((prev) => {
      return prev.map((item) => {
        return { ...item, isChecked: manageTag[item.stateName] };
      });
    });
  }, [manageTag]);

  const openPopUp = () => {
    setModalState(true);
  };

  const handleTagCheckBox = (event, { label, isChecked, stateName }) => {
    const checked = event.target.checked;

    if (label === TAG_LIST[0].label) {
      if (checked) dispatch(showHideTag({ ...TAG_SHOW, [stateName]: checked }));
      else dispatch(showHideTag({ ...TAG_STATE, [stateName]: checked }));
    } else {
      dispatch(showHideTag({ [stateName]: !isChecked }));
    }

    setTagList((prev) => {
      return prev.map((item) => {
        if (label === "Hide all" && item.label !== label)
          item.isChecked = false;
        else if (item.label === label) item.isChecked = !isChecked;

        return item;
      });
    });
  };

  const view = useMemo(() => {
    if (
      [
        "layout",
        "block-a",
        "block-b",
        "block-c",
        "block-d",
        "block-e",
        "block-f",
        "block-g",
        "block-h",
        "block-i",
        "block-j",
        "shiv-agan",
        "prem-agan",
      ].includes(viewName)
    ) {
      return viewName;
    }
  }, [viewName]);

  const handleTagToggle = () => {
    setTagShow(!tagShow);
  };

  return (
      <>
          {view && (
              <View2dPlanModal
                  open={openModal}
                  classes={{paper: "root"}}
                  onClose={() => setModalState(false)}
              >
                  <React.Fragment>
                      <Lightbox
                          open={openModal}
                          close={() => setModalState(false)}
                          plugins={[Zoom]}
                          slides={[
                              {
                                  src: require(`../../Assets/Images/2D/${view}.jpg`),
                                  alt: "image 1",
                                  width: 3840,
                                  height: 2560,
                              },
                          ]}
                          render={{
                              nextButton: () => null, // Disable next arrow
                              prevButton: () => null  // Disable previous arrow
                          }}
                      />
                      <button type="button" onClick={() => zoomRef.current?.zoomIn()}>
                          Zoom In
                      </button>

                      <button type="button" onClick={() => zoomRef.current?.zoomOut()}>
                          Zoom Out
                      </button>
                  </React.Fragment>
              </View2dPlanModal>
          )}

          {floor2DPlan && (
              <View2dPlanModal
                  open={floor2DPlan !== "" ? true : false}
                  classes={{paper: "root"}}
                  onClose={() => dispatch(showHideBlockModel(""))}
              >
                  <React.Fragment>
                    <Lightbox
                        open={!!floor2DPlan}  // Simplified truthy check
                        close={() => dispatch(showHideBlockModel(""))}
                        plugins={[Zoom]}
                        slides={[
                          {
                            src: require(`../../Assets/Images/2D/${floor2DPlan}.jpg`),
                            alt: "image 1",
                            width: 3840,
                            height: 2560,
                          },
                          ...(floor2DPlan === 'Plan_TulsiAngan'
                                  ? [
                                    {
                                      src: require(`../../Assets/Images/slider-modal/Tulsiangan_4_fullsize.jpg`),
                                      alt: "image 2",
                                      width: 3840,
                                      height: 2560,
                                    },
                                    {
                                      src: require(`../../Assets/Images/slider-modal/Jblock_10_fullsize.jpg`),
                                      alt: "image 3",
                                      width: 3840,
                                      height: 2560,
                                    },
                                  ]
                                  : floor2DPlan === 'Plan_ShivAngan_2' || floor2DPlan === 'Plan_ShivAngan'
                                      ? [
                                        {
                                          src: require(`../../Assets/Images/slider-modal/Shivangan_3_fullsize.jpg`),
                                          alt: "image 2",
                                          width: 3840,
                                          height: 2560,
                                        },
                                      ]
                                      : floor2DPlan === 'Plan_PremAngan_2' || floor2DPlan === 'Plan_PremAngan'
                                          ? [
                                            {
                                              src: require(`../../Assets/Images/slider-modal/Premangan_9_fullsize.jpg`),
                                              alt: "image 2",
                                              width: 3840,
                                              height: 2560,
                                            },
                                          ]
                                          : floor2DPlan === 'Plan_SuryaAangan_2' || floor2DPlan === 'Plan_SuryaAngan'
                                              ? [
                                                {
                                                  src: require(`../../Assets/Images/slider-modal/E_block_8_fullsize.jpg`),
                                                  alt: "image 2",
                                                  width: 3840,
                                                  height: 2560,
                                                },
                                              ]
                                              : []
                          )
                        ]}
                        render={{
                          nextButton: () => null,  // Disable next arrow
                          prevButton: () => null,  // Disable previous arrow
                        }}
                    />

                    <button type="button" onClick={() => zoomRef.current?.zoomIn()}>
                          Zoom In
                      </button>

                      <button type="button" onClick={() => zoomRef.current?.zoomOut()}>
                          Zoom Out
                      </button>
                  </React.Fragment>
              </View2dPlanModal>
          )}
          <Header/>
          {getContact && <ContactVerification open={getContact} />}

          {!removeIcon.includes(viewName) && (
              <>
                  {viewName !== "amenities-3d" && (
                      <>
                          {!isLoading && showCompass && (
                              <Box
                                  style={{
                                      backgroundImage: `url(${compass})`,
                                      height: "120px",
                                      width: "120px",
                                      top: "100px",
                                      zIndex: 1000,
                                      right: "10px",
                                      position: "fixed",
                                      backgroundColor: "#ffffff6e",
                                      borderRadius: "50%",
                                      transform: `rotate(${rotateDegree}deg)`,
                                  }}
                              />
                          )}
                      </>
                  )}
              </>
          )}

          <main className="doorverse_main_section">
              <div className="doorverse_3d_section">
                  {children}
                  {!isLoading && <Share/>}
                  {viewName === "amenities-3d" &&
                  <>
                    <div
                      style={{
                        zIndex: 999,
                        right: "12px",
                        bottom: "20px",
                        position: "fixed",
                      }}
                  >
                    <div className="bg-[#232735] rounded-[28px] flex">
                      <img id="btnFullScreen" className="cursor py-[15px] pr-[15px] pl-[15px] hover:opacity-[0.5]" src={isFullScreen ? NormalScreenWhiteIcon : FullScreenWhiteIcon} alt="fullscreen" onClick={() => toggleFullScreen()} />
                    </div>
                  </div>
                  </>
                  }
            </div>
          </main>

          {!isLoading && viewName !== "amenities-3d" && (
              <>
                  <div
                      style={{
                          zIndex: 999,
                          right: "12px",
                          bottom: "20px",
                          position: "fixed",
                      }}
                  >
                      {tagShow && (
                          <div className="bg-white py-4 px-4 rounded-2xl mb-3">
                              {tagList &&
                                  tagList.map((item, i) => (
                                      <div
                                          className={`flex items-center justify-between ${item.mb}`}
                                          key={`${item.label}_${i}`}
                                      >
                                          <div className="flex items-center">
                                              <input
                                                  type="checkbox"
                                                  id={`${item.label}_${i}`}
                                                  checked={item.isChecked}
                                                  disabled={item.disabled}
                                                  onChange={(e) => handleTagCheckBox(e, item)}
                                                  className="h-5 w-5 border-2 checked:bg-black rounded-xl text-[#FFAB2B] focus:[#FFAB2B] border-gray-300 cursor"
                                              />
                                              <label
                                                  htmlFor={`${item.label}_${i}`}
                                                  className="ml-2 block text-sm font-normal text-gray-900 cursor"
                                              >
                                                  {item.label}
                                              </label>
                                          </div>
                                          <span
                                              className="w-[10px] h-[10px] rounded-full"
                                              style={{background: item.labelColor}}
                                          ></span>
                                      </div>
                                  ))}
                          </div>
                      )}

                      <div className="bg-white rounded-[28px] flex">
                          <img
                              id="btnFullScreen"
                              className={`cursor border-r-2 py-[15px] pr-[15px] pl-[22px] hover:opacity-[0.5] ${
                                  isFullScreen
                                      ? "opacity-[0.5] bg-[#D1D5DB] btn-full-rounded"
                                      : ""
                              }`}
                              src={isFullScreen ? NormalScreenIcon : FullScreenIcon}
                              alt="fullscreen"
                              onClick={() => toggleFullScreen()}
                          />

                          {viewName !== "amenities-3d" ? (
                              <>
                                  <img
                                      className={`cursor border-r-2 py-[15px] px-[15px] hover:opacity-[0.5] ${
                                          showCompass ? "opacity-[0.5] bg-[#D1D5DB]" : ""
                                      }`}
                                      src={CompassIcon}
                                      onClick={() => dispatch(showHideCompass(!showCompass))}
                                      alt="Compass Icon"
                                  />
                                  <img
                                      className={`cursor border-r-2 py-[15px] px-[15px] hover:opacity-[0.5] ${
                                          openModal ? "opacity-[0.5] bg-[#D1D5DB]" : ""
                                      }`}
                                      src={TwoDIcon}
                                      alt="2D Icon"
                                      onClick={() => openPopUp()}
                                  />
                                  <img
                                      className={`cursor pl-[15px] pr-[17px] hover:opacity-[0.5] ${
                                          tagShow
                                              ? "opacity-[0.5] bg-[#D1D5DB] btn-tag-rounded"
                                              : ""
                                      }`}
                                      src={TagIcon}
                                      alt="Tag Icon"
                                      onClick={handleTagToggle}
                                  />
                              </>
                          ) : (
                              <>
                                  <img
                                      className={`border-r-2 py-[15px] px-[15px] hover:opacity-[0.5] opacity-[0.5] bg-[#D1D5DB]`}
                                      src={CompassIcon}
                                      alt="Compass Icon"
                                  />
                                  <img
                                      className={`cursor border-r-2 py-[15px] px-[15px] hover:opacity-[0.5] opacity-[0.5] bg-[#D1D5DB]`}
                                      src={TwoDIcon}
                                      alt="2D Icon"
                                  />
                                  <img
                                      className={`cursor pl-[15px] pr-[17px] hover:opacity-[0.5] opacity-[0.5] bg-[#D1D5DB] btn-tag-rounded`}
                                      src={TagIcon}
                                      alt="Tag Icon"
                                  />
                              </>
                          )}
                      </div>
                  </div>
              </>
          )}
      </>
  );
};

export default Layout;
